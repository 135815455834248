import Game from './Game';
import backgroundImage from './images/secret-bg-clean.png'
import logo from "./images/Green-logo-white-clean.png";
import { Card, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'Smokey Bird';
  })

  return (
    <div style={{ position: 'relative', maxWidth: '100vw' }}>
      <a href='https://www.secret-flowers.de/' style={{ marginTop: '15px', height: '42%', display: 'flex', justifyContent: 'center' }}>
          <img style={{ width: '15%' }} src={logo} alt="logo" />
      </a>
      <Card className="App" style={{
        margin: '20px',
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        minHeight: '70vh',
        backgroundSize: 'cover',
        textAlign: 'center'
      }}>
        <div style={{ margin: "10px" }}>
          <Game />
        </div>
      </Card>
      {isMobile && <Button style={{
        backgroundColor: '#268A5A',
        color: 'white',
        fontFamily: 'luckiestGuy',
        fontWeight: '400',
        padding: 20,
        height: '100%',
        fontSize: 20,
        margin: 20,
        marginTop: 0,
        display: 'block'
      }} href={"https://www.secret-flowers.de/"}>Zurück zum Shop</Button>}
    </div >
  );
}

export default App;
